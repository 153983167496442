<template>
  <section class="coupon-setting" :v-loading="loading">
    <PageTitle
      title="票券模組參數設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <section class="section-block">
      <SectionTitle title="票券設定" @edit="showDialog = true" />
      <el-form
        v-model="formData"
        label-position="left"
        label-width="250px"
      >
        <el-form-item label="票券顯示名稱" prop="displayName">
          <div class="text-[#5E5E5E]">{{ formData.displayName }}</div>
        </el-form-item>
        <el-form-item label="票券到期通知發送天數" props="expireNotificationBefore">
          <div class="text-[#5E5E5E]">{{ formData.expireNotificationBefore }}天</div>
        </el-form-item>
      </el-form>
    </section>
    <el-dialog
      title="堂票即將到期通知設定"
      :visible="showDialog"
      width="670px"
      :close-on-click-modal="false"
      @close="showDialog = false"
    >
      <el-form ref="form" :model="formData">
        <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
          <p class="font-bold"><span class="text-danger">* </span>票券顯示名稱</p>
          <el-tooltip placement="right">
            <div slot="content">
              您可以自行設定票券在客人端顯示的文字，至多 5 個字。<br>
              （票券種類有：使用券、兌換券
            </div>
            <span class="material-icons">help_outline</span>
          </el-tooltip>
        </div>
        <el-form-item prop="displayName">
          <el-input v-model="formData.displayName" clearable />
        </el-form-item>
        <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
          <p class="font-bold"><span class="text-danger">* </span>到期通知發送天數設定</p>
          <el-tooltip placement="right">
            <div slot="content">
              系統將於您在到期日前 X 天的中<br>
              午 12 點推播 Line 訊息提醒 舉<br>
              例：若設定到期日前 3 天通知假設<br>
              位客人的堂票是12/31 到期，系<br>
              統將於 12/28 的中午 12 點提醒
            </div>
            <span class="material-icons">help_outline</span>
          </el-tooltip>
        </div>
        <el-form-item prop="expireNotificationBefore">
          <el-input v-model="formData.expireNotificationBefore" clearable />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button plain @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="confirm">確定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import store from '@/store'
import { GetCouponConfig, UpdateCouponConfig } from '@/api/coupon'

export default defineComponent({
  name: 'CouponSettings',
  setup (props) {
    const loading = ref(false)
    const formData = reactive({
      displayName: '',
      expireNotificationBefore: 7,
    })
    const showDialog = ref(false)
    const shopId = computed(() => store.getters.shop)

    const confirm = async () => {
      await updateCouponConfig()
      showDialog.value = false
    }

    const getCouponConfig = async () => {
      loading.value = true
      const [res, err] = await GetCouponConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      formData.displayName = res.displayName || '票券'
      formData.expireNotificationBefore = res.expireNotificationBefore || 7
    }

    const updateCouponConfig = async () => {
      loading.value = true
      const [, err] = await UpdateCouponConfig({
        shopId: shopId.value,
        displayName: formData.displayName,
        expireNotificationBefore: formData.expireNotificationBefore,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
    }

    onMounted(() => {
      getCouponConfig()
    })
    return {
      loading,
      formData,
      showDialog,
      confirm,
    }
  },
})
</script>
